import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, TextField, Typography, Link, Button, FormControlLabel, Switch } from '@mui/material';
import { Attachments } from './ContentComponent';
import { ComponentLoading } from 'components';

import { MUTATION_ADD_POST_TO_TICKET } from 'gql';
import { useMutation } from '@apollo/client';

import { IUploadedFile, ITicket } from 'types';

import { setAlert } from 'state';
import { SET_POST_TEXT, SET_TICKET_VISIBILITY, SET_UPLOADED_FILES, SET_TICKET_ID } from 'state/types';
import { isEqualTwoArrays } from 'helpers';

interface TicketPostFormProps {
  ticket: ITicket;
}

const TicketPostForm: React.FC<TicketPostFormProps> = (props) => {
  const { ticket } = props;
  const { id } = useParams<{
    id: string;
  }>();
  const { isTechnician } = useSelector((state: any) => state.auth);

  const {
    postText: message,
    visibility,
    uploadedFileList,
    storedTicketId,
  } = useSelector((state: any) => {
    return state.ticket;
  });
  const dispatch = useDispatch();

  const setMessage = (text: string) => {
    dispatch({
      type: SET_POST_TEXT,
      payload: {
        postText: text,
      },
    });
  };

  const setVisibility = (input: string) => {
    if (visibility !== input) {
      dispatch({
        type: SET_TICKET_VISIBILITY,
        payload: {
          postText: input,
        },
      });
    }
  };

  const setUploadedFileList = (inputFiles: IUploadedFile[]) => {
    if (!isEqualTwoArrays(uploadedFileList, inputFiles)) {
      dispatch({
        type: SET_UPLOADED_FILES,
        payload: {
          uploadedFileList: [...inputFiles],
        },
      });
    }
  };

  const [messageError, setMessageError] = useState('');

  const [uploadingFiles, setUploadingFiles] = useState(false);

  const [addPost, { data: dataAddPost, loading: loadingAddPost, error: errorAddPost }] = useMutation(MUTATION_ADD_POST_TO_TICKET, {
    errorPolicy: 'all',
  });

  const setTicketId = (id: string) => {
    if (storedTicketId !== id) {
      dispatch({
        type: SET_TICKET_ID,
        payload: {
          id,
        },
      });
    }
  };

  const removeFile = (index: number) => {
    const tmp = [...uploadedFileList];
    tmp.splice(index, 1);
    setUploadedFileList([...tmp]);
  };

  const onSubmit = async () => {
    if (!message) {
      setMessageError('Required');
      return;
    }

    if (!ticket?.id) return;

    const documentIds = uploadedFileList
      .filter((file: IUploadedFile) => file.success)
      .map((file: IUploadedFile) => {
        return file.id;
      });

    await addPost({
      variables: {
        ticketId: parseInt(ticket.id),
        body: message,
        documentIds,
        visibility: visibility,
      },
    });
  };

  const getVisibilityName = (visibililty: string) => {
    if (visibililty === 'M,C') return 'Savvy';

    if (visibililty === 'M,C,T') return 'Savvy and Shop';

    if (visibililty === 'C,X') return 'Savvy Management';

    return '';
  };

  const handleChangeVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setVisibility('M,C,T');
    } else {
      setVisibility('M,C');
    }
  };

  useEffect(() => {
    if (id !== storedTicketId && id) {
      setTicketId(id);
      setMessage('');
      setMessageError('');
      setUploadedFileList([]);
      setVisibility(isTechnician ? 'M,C,T' : 'M,C');
    }
  }, [id, storedTicketId, isTechnician]);

  useEffect(() => {
    if (errorAddPost) {
      if (errorAddPost?.graphQLErrors[0]?.message) {
        setMessageError(errorAddPost.graphQLErrors[0].message);
      } else {
        dispatch(setAlert('error', 'Unable to post.'));
      }
    } else if (dataAddPost) {
      if (dataAddPost.addPostToTicket?.ok) {
        dispatch(setAlert('success', 'Post created.'));
        setMessage('');
        setUploadedFileList([]);
      } else {
        dispatch(setAlert('error', dataAddPost.addPostToTicket?.error || 'Unable to post.'));
      }
    }
  }, [errorAddPost, dataAddPost, dispatch]);

  useEffect(() => {
    if (isTechnician) {
      setVisibility('M,C,T');
    }
  }, [isTechnician]);

  return (
    <Box>
      <ComponentLoading loading={loadingAddPost}>
        <React.Fragment>
          <Box
            sx={{
              pt: {
                xs: 2,
                xl: 0,
              },
              pb: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <TextField
                name="message"
                multiline
                minRows={5}
                fullWidth
                value={message}
                onChange={(e: any) => {
                  setMessage(e.target.value);
                  setMessageError('');
                }}
                error={Boolean(messageError)}
                helperText={messageError}
                sx={{
                  mb: 0,
                  '& .MuiInputBase-root': {
                    pb: 4.5,
                  },
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                }}
              >
                <Attachments
                  uploadingFiles={uploadingFiles}
                  setUploadingFiles={setUploadingFiles}
                  uploadedFileList={uploadedFileList}
                  setUploadedFileList={setUploadedFileList}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              pb: 2,
              px: 2,
            }}
          >
            {uploadedFileList.map((uploadedFile: IUploadedFile, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    py: 0.5,
                  }}
                >
                  <Typography>{uploadedFile.name}</Typography>
                  <Typography
                    sx={{
                      color: uploadedFile.success ? '#4C8F5B' : '#E61B23',
                      marginLeft: '15px',
                    }}
                  >
                    {uploadedFile.success ? 'Uploaded' : 'Failed'}
                  </Typography>
                  {uploadedFile.success && (
                    <Link
                      href="#"
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeFile(index);
                      }}
                      sx={{
                        marginLeft: '15px',
                      }}
                    >
                      Remove
                    </Link>
                  )}
                </Box>
              );
            })}
          </Box>
          <Box
            pt={1}
            pb={4}
            px={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              borderBottom: '1px solid #E9EAEF',
            }}
          >
            {ticket?.watchingServiceCenters && ticket?.watchingServiceCenters?.length > 0 && !isTechnician && (
              <FormControlLabel
                control={<Switch checked={visibility === 'M,C,T'} onChange={handleChangeVisibility} />}
                label={'Post Visible to ' + getVisibilityName(visibility)}
                sx={{
                  width: '250px',
                }}
              />
            )}
            <Button
              color="primary"
              variant="contained"
              sx={{
                px: 6,
                ml: 2,
              }}
              onClick={() => {
                onSubmit();
              }}
              disabled={uploadingFiles}
            >
              Send
            </Button>
          </Box>
        </React.Fragment>
      </ComponentLoading>
    </Box>
  );
};

export default TicketPostForm;
